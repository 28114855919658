import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Experiments from '../views/Experiments.vue'
import Models from '../views/Models.vue'
import Bevindingen from '../views/Findings.vue'
import ARViewer from '../views/ARViewer.vue'
import Svg from '../views/Svg.vue'
import Design from '../views/Design.vue'
import Test from '../views/Test.vue'
import Pano from '../views/360.vue'
import PanoFiles from '../views/360files.vue'
import Videos from '../views/Videos.vue'
import QR from '../views/QR.vue'
import Metrics from '../views/Metrics.vue'
import Viewer3D from '../views/3DViewer.vue'
import ModelViewer from '../views/ModelViewer.vue'
import My360 from '../views/My360.vue'
import My360View from '../views/My360View.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Digital Urban Planning Amsterdam / R&D',
    component: Home
  },
  {
    path: '/design',
    name: 'Design',
    component: Design
  },
  {
    path: '/models',
    name: 'Models',
    component: Models
  },
  {
    path: '/experiments',
    name: 'Experiments',
    component: Experiments
  },
  {
    path: '/findings',
    name: 'Findings',
    component: Bevindingen
  },
  {
    path: '/ar/:shortCode',
    name: 'ARViewer',
    component: ARViewer,
    props: true
  },
  {
    path: '/svg',
    name: 'SVG',
    component: Svg
  },
  {
    path: '/test',
    name: 'Test',
    component: Test
  },
  {
    path: '/modelviewer/:shortCode?',
    name: 'ModelViewer',
    component: ModelViewer,
    props: true
  },
  {
    path: '/360files',
    name: '360 files',
    component: PanoFiles
  },
  {
    path: '/360/:shortcode',
    name: '360 file demo',
    component: Pano
  },
  {
    path: '/my360/',
    name: 'My360',
    component: My360
  },
  { 
    path: '/my360/:shortcode', 
    name: 'My360View',
    component: My360View, 
    props: true 
  },
  {
    path: '/videos',
    name: 'Videos',
    component: Videos
  },
  {
    path: '/qr',
    name: 'QR',
    component: QR
  },
  {
    path: '/metrics',
    name: 'Metrics',
    component: Metrics
  },
  {
    path: '/3d/:shortCode',
    name: '3D Viewer',
    component: Viewer3D,
    props: true
  }

]

const router = new VueRouter({
  routes,
  scrollBehavior() {
    document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
}
})

export default router
