<template>

    <div ref="threeContainer" class="three-container">

        <svg v-if="useControls == false && isFullcreen == false" 
          class="centerIcon" 
          :class="{ show: showControlIcon, hide: !showControlIcon }"
          xmlns="http://www.w3.org/2000/svg" 
          xmlns:xlink="http://www.w3.org/1999/xlink" 
          width="25" 
          height="36">
          <defs>
              <path id="A" d="M.001.232h24.997V36H.001z"></path>
          </defs>
          <g transform="translate(-11 -4)" fill="none" fill-rule="evenodd">
              <path fill-opacity="0" fill="#fff" d="M0 0h44v44H0z"></path>
              <g transform="translate(11 3)">
                  <path d="M8.733 11.165c.04-1.108.766-2.027 1.743-2.307a2.54 2.54 0 0 1 .628-.089c.16 0 .314.017.463.044 1.088.2 1.9 1.092 1.9 2.16v8.88h1.26c2.943-1.39 5-4.45 5-8.025a9.01 9.01 0 0 0-1.9-5.56l-.43-.5c-.765-.838-1.683-1.522-2.712-2-1.057-.49-2.226-.77-3.46-.77s-2.4.278-3.46.77c-1.03.478-1.947 1.162-2.71 2l-.43.5a9.01 9.01 0 0 0-1.9 5.56 9.04 9.04 0 0 0 .094 1.305c.03.21.088.41.13.617l.136.624c.083.286.196.56.305.832l.124.333a8.78 8.78 0 0 0 .509.953l.065.122a8.69 8.69 0 0 0 3.521 3.191l1.11.537v-9.178z" fill-opacity=".5" fill="#e4e4e4"></path>
                  <path d="M22.94 26.218l-2.76 7.74c-.172.485-.676.8-1.253.8H12.24c-1.606 0-3.092-.68-3.98-1.82-1.592-2.048-3.647-3.822-6.11-5.27-.095-.055-.15-.137-.152-.23-.004-.1.046-.196.193-.297.56-.393 1.234-.6 1.926-.6a3.43 3.43 0 0 1 .691.069l4.922.994V10.972c0-.663.615-1.203 1.37-1.203s1.373.54 1.373 1.203v9.882h2.953c.273 0 .533.073.757.21l6.257 3.874c.027.017.045.042.07.06.41.296.586.77.426 1.22M4.1 16.614c-.024-.04-.042-.083-.065-.122a8.69 8.69 0 0 1-.509-.953c-.048-.107-.08-.223-.124-.333l-.305-.832c-.058-.202-.09-.416-.136-.624l-.13-.617a9.03 9.03 0 0 1-.094-1.305c0-2.107.714-4.04 1.9-5.56l.43-.5c.764-.84 1.682-1.523 2.71-2 1.058-.49 2.226-.77 3.46-.77s2.402.28 3.46.77c1.03.477 1.947 1.16 2.712 2l.428.5a9 9 0 0 1 1.901 5.559c0 3.577-2.056 6.636-5 8.026h-1.26v-8.882c0-1.067-.822-1.96-1.9-2.16-.15-.028-.304-.044-.463-.044-.22 0-.427.037-.628.09-.977.28-1.703 1.198-1.743 2.306v9.178l-1.11-.537C6.18 19.098 4.96 18 4.1 16.614M22.97 24.09l-6.256-3.874c-.102-.063-.218-.098-.33-.144 2.683-1.8 4.354-4.855 4.354-8.243 0-.486-.037-.964-.104-1.43a9.97 9.97 0 0 0-1.57-4.128l-.295-.408-.066-.092a10.05 10.05 0 0 0-.949-1.078c-.342-.334-.708-.643-1.094-.922-1.155-.834-2.492-1.412-3.94-1.65l-.732-.088-.748-.03a9.29 9.29 0 0 0-1.482.119c-1.447.238-2.786.816-3.94 1.65a9.33 9.33 0 0 0-.813.686 9.59 9.59 0 0 0-.845.877l-.385.437-.36.5-.288.468-.418.778-.04.09c-.593 1.28-.93 2.71-.93 4.222 0 3.832 2.182 7.342 5.56 8.938l1.437.68v4.946L5 25.64a4.44 4.44 0 0 0-.888-.086c-.017 0-.034.003-.05.003-.252.004-.503.033-.75.08a5.08 5.08 0 0 0-.237.056c-.193.046-.382.107-.568.18-.075.03-.15.057-.225.1-.25.114-.494.244-.723.405a1.31 1.31 0 0 0-.566 1.122 1.28 1.28 0 0 0 .645 1.051C4 29.925 5.96 31.614 7.473 33.563a5.06 5.06 0 0 0 .434.491c1.086 1.082 2.656 1.713 4.326 1.715h6.697c.748-.001 1.43-.333 1.858-.872.142-.18.256-.38.336-.602l2.757-7.74c.094-.26.13-.53.112-.794s-.088-.52-.203-.76a2.19 2.19 0 0 0-.821-.91" fill-opacity=".6" fill="#000"></path>
                  <path d="M22.444 24.94l-6.257-3.874a1.45 1.45 0 0 0-.757-.211h-2.953v-9.88c0-.663-.616-1.203-1.373-1.203s-1.37.54-1.37 1.203v16.643l-4.922-.994a3.44 3.44 0 0 0-.692-.069 3.35 3.35 0 0 0-1.925.598c-.147.102-.198.198-.194.298.004.094.058.176.153.23 2.462 1.448 4.517 3.22 6.11 5.27.887 1.14 2.373 1.82 3.98 1.82h6.686c.577 0 1.08-.326 1.253-.8l2.76-7.74c.16-.448-.017-.923-.426-1.22-.025-.02-.043-.043-.07-.06z" fill="#fff"></path>
                  <g transform="translate(0 .769)">
                      <mask id="B" fill="#fff">
                          <use xlink:href="#A"></use>
                      </mask>
                      <path d="M23.993 24.992a1.96 1.96 0 0 1-.111.794l-2.758 7.74c-.08.22-.194.423-.336.602-.427.54-1.11.87-1.857.872h-6.698c-1.67-.002-3.24-.633-4.326-1.715-.154-.154-.3-.318-.434-.49C5.96 30.846 4 29.157 1.646 27.773c-.385-.225-.626-.618-.645-1.05a1.31 1.31 0 0 1 .566-1.122 4.56 4.56 0 0 1 .723-.405l.225-.1a4.3 4.3 0 0 1 .568-.18l.237-.056c.248-.046.5-.075.75-.08.018 0 .034-.003.05-.003.303-.001.597.027.89.086l3.722.752V20.68l-1.436-.68c-3.377-1.596-5.56-5.106-5.56-8.938 0-1.51.336-2.94.93-4.222.015-.03.025-.06.04-.09.127-.267.268-.525.418-.778.093-.16.186-.316.288-.468.063-.095.133-.186.2-.277L3.773 5c.118-.155.26-.29.385-.437.266-.3.544-.604.845-.877a9.33 9.33 0 0 1 .813-.686C6.97 2.167 8.31 1.59 9.757 1.35a9.27 9.27 0 0 1 1.481-.119 8.82 8.82 0 0 1 .748.031c.247.02.49.05.733.088 1.448.238 2.786.816 3.94 1.65.387.28.752.588 1.094.922a9.94 9.94 0 0 1 .949 1.078l.066.092c.102.133.203.268.295.408a9.97 9.97 0 0 1 1.571 4.128c.066.467.103.945.103 1.43 0 3.388-1.67 6.453-4.353 8.243.11.046.227.08.33.144l6.256 3.874c.37.23.645.55.82.9.115.24.185.498.203.76m.697-1.195c-.265-.55-.677-1.007-1.194-1.326l-5.323-3.297c2.255-2.037 3.564-4.97 3.564-8.114 0-2.19-.637-4.304-1.84-6.114-.126-.188-.26-.37-.4-.552-.645-.848-1.402-1.6-2.252-2.204C15.472.91 13.393.232 11.238.232A10.21 10.21 0 0 0 5.23 2.19c-.848.614-1.606 1.356-2.253 2.205-.136.18-.272.363-.398.55C1.374 6.756.737 8.87.737 11.06c0 4.218 2.407 8.08 6.133 9.842l.863.41v3.092l-2.525-.51c-.356-.07-.717-.106-1.076-.106a5.45 5.45 0 0 0-3.14.996c-.653.46-1.022 1.202-.99 1.983a2.28 2.28 0 0 0 1.138 1.872c2.24 1.318 4.106 2.923 5.543 4.772 1.26 1.62 3.333 2.59 5.55 2.592h6.698c1.42-.001 2.68-.86 3.134-2.138l2.76-7.74c.272-.757.224-1.584-.134-2.325" fill-opacity=".05" fill="#000" mask="url(#B)"></path>
                  </g>
              </g>
          </g>
      </svg>

      <div v-if="currentUseControls" class="overlay">
          <b-button v-if="showAddTiles" @click="addTiles">Add Tiles</b-button>
          <b-button @click="downloadImage">Download Image</b-button>

          <b-button @click="download360">Download 360</b-button>

          <b-button ref="realistic"  @click="toggleRendering">
            <input type="checkbox" v-model="checkboxRealistic" />
            Realistic
          </b-button>

          <b-button  v-if="usePathTracing" @click="toggle360">
            <input type="checkbox" v-model="is360" />
            360
          </b-button>

    </div>

    <div ref="guiContainer" class="menu">
    </div>

    <div v-if="usePathTracing" class="sampleCounter">Samples: {{ sampleCount }}</div>

    <button class="fullscreen-btn" @click="toggleFullscreen">⛶</button>

    <b-modal v-model="showModal" title="Model Info">
      <div>
        <div> Name: {{ modelName }} </div>
        <div> X: {{ modelGroupX }} </div>
        <div> Y: {{ modelGroupY }} </div>
      </div>
      <template #modal-footer>
        <b-button variant="secondary" @click="showModal = false">Sluiten</b-button>
      </template>
    </b-modal>

    </div>

</template>

<script>
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { EquirectCamera, WebGLPathTracer} from "three-gpu-pathtracer";
import { GUI } from 'three/examples/jsm/libs/lil-gui.module.min.js';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';
import { ParallelMeshBVHWorker } from 'three-mesh-bvh/src/workers/ParallelMeshBVHWorker.js';
import { generateRadialFloorTexture } from '../generateRadialFloorTexture.js';
import TileFetcher from '@/TileFetcher';
import DragControls from 'three-dragcontrols';
import CubemapToEquirectangular from "@/CubemapToEquirectangular";

export default {
  name: 'ThreeViewer',
  props: {
    shortCode: {
      type: String,
      required: false
    },
    rdx: {
      type: Number,
      default: null
    },
    rdy: {
      type: Number,
      default: null
    },
    useControls: {
      type: Boolean,
      default: false
    },
    showTileGrid: {
      type: Boolean,
      default: false
    },
    tileRadius: {
      type: Number,
      default: 1000
    }
  },
  async mounted() {

    if(this.rdx && this.rdy){
      this.currentRDX = this.rdx;
      this.currentRDY = this.rdy;
    }

    this.currentUseControls = this.useControls;

    this.initThreeJS();

    await this.loadEnvMap();

    if(this.shortCode){
      let url = await this.getUrl();

      if(this.currentRDX){
        this.addTiles();
      }

      this.loadModel(url);

      //this.addMarker(3, 0,0,0);
    }
    else {
      this.addTiles();
    }

    this.createUI();
    if(this.currentUseControls == true){
      this.showUI();
    }

    document.addEventListener("fullscreenchange", () => {
      if (document.fullscreenElement) {
        this.isFullcreen = true;
        this.currentUseControls = true;
        this.showUI();
      } else {
        this.isFullcreen = false;

        this.currentUseControls = this.useControls;
        
        //we are configured to be an embedded viewer without the controls, so hide ui and reset
        if(this.useControls == false)
        {
          this.hideUI();
          this.checkboxRealistic = false;
          this.usePathTracing = false;
          this.is360 = false;
          this.controls.enabled = true;
        }
        this.threecontainer.style.width = this.lastWidth + "px";
        this.threecontainer.style.height = this.lastHeight + "px";
      }
    });

  },
  data: function () {
      return {
        equiManaged: null,
        raycaster: new THREE.Raycaster(),
        mouse: new THREE.Vector2(),
        showModal:false,
        modelName: "",
        modelGroupX: null,
        modelGroupY: null,
        showControlIcon:true,
        controlStarted:false,
        currentRDX: null,
        currentRDY: null,
        dragControls:null,
        dragobjects:[],
        enableDraggingGui:null,
        boxedModelCenterY:null,
        model:null,
        modelGroup:null,
        currentUseControls: null,
        isFullcreen: false,
        checkboxRealistic:false,
        lastWidth:null,
        lastHeight:null,
        threecontainer:null,
        gui:null,
        planeSize:500,
        upscaleThreshold: 10,
        scene:null,
        currentcamera:null,
        camera:null,
        camera360:null,
        controls:null,
        sunlight: null,
        ambientLight: null,
        animationFrameId: null,
        renderer:null,
        usePathTracing: false,
        is360: false,
        sampleCount: 0,
        boxedModel: null,
        params:{
          ambientColor: '#ffffff',
          ambientIntensity: 0.058,
          lightColor: '#ffffff',
          lightIntensity: 3,
          angle:114,
          lightHeight: 3.52,
          environmentIntensity: 0.4,
          environmentRotation:4.4,
          filterGlossyFactor: 0.5,
          envMap:"",
          envMaps : {
            'Pure Sky': 'https://raw.githubusercontent.com/gkjohnson/3d-demo-data/master/hdri/aristea_wreck_puresky_2k.hdr',
            'Moonless Golf': 'https://raw.githubusercontent.com/mrdoob/three.js/dev/examples/textures/equirectangular/moonless_golf_1k.hdr',
            'Noon Grass': 'https://raw.githubusercontent.com/gkjohnson/3d-demo-data/master/hdri/noon_grass_2k.hdr',
          'Sepulchral Chapel Rotunda': 'https://raw.githubusercontent.com/gkjohnson/3d-demo-data/master/hdri/sepulchral_chapel_rotunda_2k.hdr'
          },
          neighbourTileThreshold: this.tileRadius,
          modelRotation:0,
          enableDragging: false,
          showGridLines:this.showTileGrid,
          modelX: "dit is een tekst"
        },
        wmtsLayers: [
        {
              name: "PDOK Luchtfoto",
              url: "https://service.pdok.nl/hwh/luchtfotorgb/wmts/v1_0?",
              layer: "Actueel_ortho25"
          },
          {
              name: "BRT Achtergrondkaart",
              url: "https://service.pdok.nl/brt/achtergrondkaart/wmts/v2_0?",
              layer: "standaard"
          },
          {
              name: "BRT Achtergrondkaart (Grijs)",
              url: "https://service.pdok.nl/brt/achtergrondkaart/wmts/v2_0?",
              layer: "grijs"
          }
        ]
      };
  },
  methods: {
    initThreeJS() {
      this.scene = new THREE.Scene();
      this.threecontainer = this.$refs.threeContainer; 

      this.hideControlIcon = () => {
        if(this.controlStarted){
          this.showControlIcon = false;
        }
      };

      this.showControlIconHandler = () => {
        this.showControlIcon = true;
      };

      this.threecontainer.addEventListener('mouseenter', this.hideControlIcon);
      this.threecontainer.addEventListener('mouseleave', this.showControlIconHandler);
      window.addEventListener('click', this.onMouseClick, false);


      this.renderer = new THREE.WebGLRenderer({
        antialias: true,
        preserveDrawingBuffer: true,  // Zorg ervoor dat de canvas behouden blijft na renderen,
      });

      this.renderer.setClearColor(0xeeeeee, 1);

      //this.renderer.toneMapping = THREE.ACESFilmicToneMapping;

      this.renderer.setPixelRatio(window.devicePixelRatio);

      this.renderer.shadowMap.enabled = true; // Enable shadows
      this.renderer.shadowMap.type = THREE.PCFSoftShadowMap; // Zachtere schaduwen

      this.$refs.threeContainer.appendChild(this.renderer.domElement);

      this.renderer.domElement.addEventListener("dblclick", this.onDoubleClick);


  //    await this.loadEnvMap();

      const cameraNear = 1;
      const cameraFar = 40000;

      this.camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, cameraNear, cameraFar);
      this.camera.position.set(0, 100, 300);
      this.camera.lookAt(0, 0, 0);

      this.updateRendereAndCamera();

      this.camera360 = new EquirectCamera();

      this.currentcamera = this.camera;

      this.addSunlight();

      this.updateLightParams();

      this.pathTracer = new WebGLPathTracer(this.renderer);
      this.pathTracer.setBVHWorker( new ParallelMeshBVHWorker());
      this.pathTracer.physicallyCorrectLights = true;
      this.pathTracer.tiles.set( 3, 3 );
      this.pathTracer.multipleImportanceSampling = true;
      this.pathTracer.transmissiveBounces = 10;
      this.pathTracer.minSamples = 0;

      this.pathTracer.setScene(this.scene, this.currentcamera);  

   //   this.addFloor();

      this.addControls();

      this.animate();

      window.addEventListener( 'resize', this.onResize );

      // Create a managed CubemapToEquirectangular
      this.equiManaged = new CubemapToEquirectangular( this.renderer, true );

    },
    download360() {
      console.log("Downloading 360 image...");
      this.equiManaged.update( this.camera, this.scene );
    },
    onDoubleClick(event) {

      this.mouse.x = (event.offsetX / window.innerWidth) * 2 - 1;
      this.mouse.y = - (event.offsetY / window.innerHeight) * 2 + 1;

      this.raycaster.setFromCamera(this.mouse, this.camera);

      const intersects = this.raycaster.intersectObjects(this.scene.children);  

      if (intersects.length > 0) {
        const intersect = intersects[0];
        this.camera.position.set( intersect.point.x, 2, intersect.point.z);
      }


    },
    onMouseClick(event) {

     if (!event.shiftKey) return;

    this.mouse.x = (event.offsetX / window.innerWidth) * 2 - 1;
    this.mouse.y = - (event.offsetY / window.innerHeight) * 2 + 1;

    this.raycaster.setFromCamera(this.mouse, this.camera);

    const intersects = this.raycaster.intersectObjects(this.scene.children);  

    if (intersects.length > 0) {
      const intersect = intersects[0];
      this.addTree(intersect.point.x, intersect.point.y, intersect.point.z);

    }
},
    canvasClicked(){
      console.log("canvas clicked")
    },
    toggleFullscreen() {
      if (!document.fullscreenElement) {
        this.lastWidth = this.threecontainer.clientWidth;
        this.lastHeight = this.threecontainer.clientHeight;
        this.threecontainer.requestFullscreen();
      } else {
        document.exitFullscreen();
      }
    },
    onParamsChange() {
      this.pathTracer.filterGlossyFactor = this.params.filterGlossyFactor;

      this.scene.environmentIntensity = this.params.environmentIntensity;
      this.scene.environmentRotation.y = this.params.environmentRotation;

      this.scene.background = this.scene.environment;
      this.scene.backgroundIntensity = this.params.environmentIntensity;
      this.scene.backgroundRotation.y = this.params.environmentRotation;

      this.pathTracer.updateMaterials();
      this.pathTracer.updateEnvironment();
    },
    onModelChange(){
     this.modelGroup.rotation.y = (this.params.modelRotation * Math.PI) / 180; // graden naar radians
    },
    onBuildingsChange(){
    },
    onResize() {
      this.updateRendereAndCamera();
      this.pathTracer.updateCamera();
    },
    updateRendereAndCamera(){
      const dpr = window.devicePixelRatio;
      this.renderer.setPixelRatio( dpr );

      this.renderer.setSize(this.threecontainer.clientWidth, this.threecontainer.clientHeight);
  
      const w =  this.threecontainer.clientWidth;
      const h =  this.threecontainer.clientHeight;
      const aspect = w / h;
      this.camera.aspect = aspect;

      this.camera.updateProjectionMatrix();
    },
    addSunlight(){
      this.sunlight = new THREE.DirectionalLight(this.params.lightColor, this.params.lightIntensity);
    //  this.sunlight.position.set(10, this.params.lightHeight, 10); // Verhoog de hoogte aanzienlijk om een groot gebied te verlichten
      this.sunlight.castShadow = true;
      this.sunlight.shadow.mapSize.width = 4096; // Verhoog de schaduwkwaliteit
      this.sunlight.shadow.mapSize.height = 4096;
      this.sunlight.shadow.bias = -0.0005;  
      this.sunlight.shadow.camera.left = -1000;    // Halve breedte van het gebied
      this.sunlight.shadow.camera.right = 1000;
      this.sunlight.shadow.camera.top = 1000;
      this.sunlight.shadow.camera.bottom = -1000;
      this.sunlight.shadow.camera.near = 1;       // Beginpunt van het schaduwvolume (dichtbij)
      this.sunlight.shadow.camera.far = 10000;     // Verre afstand voor het schaduwvolume
      this.scene.add(this.sunlight);
    },
    addFloor(){

      const floorTex = generateRadialFloorTexture( 2048 );

      var floorPlane = new THREE.Mesh(
        new THREE.PlaneGeometry(),
        new THREE.MeshStandardMaterial( {
          map:floorTex,
          transparent: true,
          color: 0xffffff,
          roughness: 1,
          metalness: 0.0,
          side: THREE.DoubleSide,
        } )
      );
      floorPlane.name = "floor";
      floorPlane.scale.setScalar( 500 );
      floorPlane.rotation.x = - Math.PI / 2;
      floorPlane.receiveShadow = true; 
      this.scene.add( floorPlane );
    },
    addControls(){
      this.controls = new OrbitControls( this.camera, this.renderer.domElement );


      this.controls.addEventListener( 'change', () => {
        this.pathTracer.updateCamera();
      } );

      this.controls.addEventListener('start', () => {
        this.controlStarted = true;
        this.showControlIcon = false;
      });

      this.controls.object = this.camera;
      this.controls.update();
      this.pathTracer.setCamera( this.camera );
    },
    addTiles(){
      if(this.tileFetcher == null){

        let tileOptions = {
          url3dtileset: "https://data.3dbag.nl/v20241216/3dtiles/lod22/tileset.json",
          wmtsurl: this.wmtsLayers[0].url,
          wmtslayer: this.wmtsLayers[0].layer,
          wmtsCrs:"EPSG:28992", 
          wmtsZoom:14,
          wmtsGridSize:4,
          showTileGrid: this.showTileGrid,
          hideCenterBuilding:true,
          scene: this.scene,
          threshold: this.params.neighbourTileThreshold,
          rdx: this.currentRDX, 
          rdy: this.currentRDY
        }
      this.tileFetcher = new TileFetcher(tileOptions);
      }
    },
    async getUrl() {
    try {
        const response = await fetch(`${this.$store.apiUrl}/geturl/${this.shortCode}`);
        const data = await response.json();

        this.modelName = this.$utils.extractFileName(data.url);

        if(data.rdx && data.rdy){
          this.currentRDX = data.rdx;
          this.currentRDY = data.rdy;
        }
        
        this.params.modelRotation = data.rotation;

        return data.url;
    } catch (error) {
        console.error('Fout bij het ophalen van het glb model:', error);
    }
},
    addMarker(size, x,y,z){
      //const geometry = new THREE.BoxGeometry(size, size, size);
      const geometry = new THREE.SphereGeometry( size, 32, 16 );

      const material = new THREE.MeshStandardMaterial({
        color: 0xffffff,
        roughness: 0,
        metalness: 1,
      });
      const cube = new THREE.Mesh(geometry, material);
      //cube.castShadow = true;
      cube.position.set(x,y,z);
      this.scene.add(cube);
    },
    downloadImage(){
      const canvas = this.renderer.domElement;
      const image = canvas.toDataURL('image/png');
      const link = document.createElement('a');
      link.href = image;
      link.download = 'render.png';
      link.click();
    },
    addTree(x,y,z){
      const loader = new GLTFLoader();

      loader.load("assets/giant_low_poly_tree.glb", (gltf) => {
                    let boom = gltf.scene;
                    boom.name = "boom";
                    boom.position.set(x,y,z);
                    this.scene.add(boom);
      });


    },
    loadModel(url_glb){
            const loader = new GLTFLoader();

            loader.load(url_glb, (gltf) => {
                    this.model = gltf.scene;
                    this.model.name = "glb model";
                    this.model.traverse((object) => {
                        if (object.isMesh && object.name !== 'floor' && object.name !== "Cubes") {
                          object.material.roughness = 0.3;
                          object.material.metalness = 0.4;
                          object.castShadow = true;
                          object.receiveShadow = true;
                         // console.log(`Materialen van ${object.name}:`, object.material);
                        }
                    });

              const box = new THREE.Box3().setFromObject(this.model);
              const boxSize = box.getSize(new THREE.Vector3());
              const boxCenter = box.getCenter(new THREE.Vector3());

              const geometry = new THREE.BoxGeometry(boxSize.x, boxSize.y, boxSize.z);
              const material = new THREE.MeshBasicMaterial({
                color: new THREE.Color(0xff0000),
                transparent: true, 
                opacity: 0
              });
              this.boxedModel = new THREE.Mesh(geometry, material);
              this.boxedModel.name = "invisiblebox";
              
              //center model
              this.model.position.set(-boxCenter.x, this.model.position.y, -boxCenter.z);

              let boxhelper = new THREE.BoxHelper(this.boxedModel, 0xffff00);
              this.boxedModel.add(boxhelper);

              this.boxedModelCenterY = boxSize.y / 2;
              this.boxedModel.position.y = this.model.position.y + this.boxedModelCenterY;
              
              this.modelGroup = new THREE.Group();
              this.modelGroup.add(this.model);
              this.scene.add(this.modelGroup);

              this.onModelChange(); //update rotation, rotation value retrieved by backend getUrl
              
              this.dragControls = new DragControls(this.dragobjects, this.camera, this.renderer.domElement);

              var that = this;

              this.dragControls.addEventListener('dragstart', function() {
                  that.controls.enabled = false;
              });

              this.dragControls.addEventListener('dragend', function() {
                  that.controls.enabled = true;
              });

              this.dragControls.addEventListener('drag', function(event) {                
                that.modelGroup.position.x = event.object.position.x;
                that.modelGroup.position.z = event.object.position.z;

                that.modelGroupX = that.modelGroup.position.x;
                that.modelGroupY = that.modelGroup.position.z;

                console.log(`modelgroup x: ${that.modelGroup.position.x} modelgroup z:${that.modelGroup.position.z}`)
                event.object.position.y = that.boxedModelCenterY; 
              });

            });
    },
    debugScene(scene) {
      const totalBoundingBox = new THREE.Box3();

      scene.traverse((child) => {
        if (child.isMesh && child.name !== "floor" && child.name !== "Cubes") {
          child.geometry.computeBoundingBox();
          const boundingBox = child.geometry.boundingBox.clone().applyMatrix4(child.matrixWorld);
          totalBoundingBox.union(boundingBox);
        }
      });

      const size = new THREE.Vector3();
      totalBoundingBox.getSize(size);

      const width = size.x;
      const height = size.y;
      const depth = size.z;

      // Bereken het midden van de totale bounding box
      const center = totalBoundingBox.getCenter(new THREE.Vector3());
      console.log(`Total Dimensions: Width: ${width}m, Height: ${height}m, Depth: ${depth}m`);
      console.log(`Center of Bounding Box: (${center.x}, ${center.y}, ${center.z})`);
    },
    calculateUVs(geometry) {
      const position = geometry.attributes.position.array;
      const uvs = [];

      for (let i = 0; i < position.length; i += 3) {
          // Eenvoudige UV-projectie (bijvoorbeeld orthografisch)
          const x = position[i];
          const z = position[i + 2]; // Gebruik Z voor 2D projectie

          // Normeer de waarden naar een [0, 1] bereik
          uvs.push((x + 1) / 2); // X naar [0, 1]
          uvs.push((z + 1) / 2); // Z naar [0, 1]
      }
      return uvs;
    },
    toggleRendering() {

      this.checkboxRealistic = !this.checkboxRealistic;
      
      this.params.enableDragging = false;
      this.enableDraggingGui.updateDisplay();
      this.handleEnableDragging(false);

      this.controls.enabled = true;
      this.is360 = false;

      if(this.checkboxRealistic){


        setTimeout(() => {
          this.pathTracer.setScene(this.scene, this.camera);
          this.usePathTracing = true;
        }, 300);
      }
      else{
        setTimeout(() => {
          this.usePathTracing = false;
        }, 300);
      }
    },
    toggle360() {

      this.is360 = !this.is360;

      if (this.is360) {
        this.controls.enabled = false;
        this.camera360.position.copy(this.camera.position);
        this.currentcamera = this.camera360;
      }
      else{
        this.controls.enabled = true;
        this.currentcamera = this.camera;
      }
      this.pathTracer.setScene(this.scene, this.currentcamera);
      this.pathTracer.updateCamera();


    },
    downloadRender() {
      const canvas = this.renderer.domElement;
      const image = canvas.toDataURL('image/png');
      const link = document.createElement('a');
      link.href = image;
      link.download = 'render.png';
      link.click();
    },
    animate() {
      this.animationFrameId = requestAnimationFrame(this.animate);
      if (this.usePathTracing) {
        this.pathTracer.renderSample();
        this.sampleCount = parseInt(this.pathTracer.samples, 10);
      } else {
        this.renderer.render(this.scene, this.camera);
      }
      this.controls.update();
    },
    createUI(){
      this.gui = new GUI({ autoPlace: false });
      this.addGuiElements();

      const env = this.gui.addFolder('Environment');
      env.add( this.params, 'envMap', this.params.envMaps ).name( 'map' ).onChange( this.loadEnvMap) ;
      env.add(this.params, 'environmentIntensity', 0, 1).onChange(this.onParamsChange);
      env.add(this.params, 'environmentRotation', 0, 2 * Math.PI).onChange(this.onParamsChange);

      const config = { selectedIndex: 0 }; // Standaard eerste laag

      const layerOptions = this.wmtsLayers.reduce((acc, layer, index) => {
          acc[layer.name] = index;
          return acc;
      }, {});

      env.add(config, "selectedIndex", layerOptions)
          .name("WMTS Layer")
          .onChange(index => {
              const selected = this.wmtsLayers[index];
              console.log(selected);
              this.tileFetcher.updateWmtsTile(selected.url, selected.layer);
          });

      env.add(this.params, 'showGridLines')
        .name('Show Gridlines')
        .onChange((value) => {
          this.tileFetcher.toggleTileGrid(value);
          //this.handleEnableDragging(value);
        });

      const lightFolder = this.gui.addFolder('Sun Light');
      lightFolder.add(this.params, 'lightIntensity', 0, 5).onChange(this.updateLightParams);
      lightFolder.addColor(this.params, 'lightColor').onChange(this.updateLightParams);
      lightFolder.add(this.params, 'angle', 0, 360).name('Light Rotation').onChange(this.updateLightParams);
      lightFolder.add(this.params, 'lightHeight', 0, 10).name('Light Height').onChange(this.updateLightParams); // Slider voor hoogte 
      lightFolder.open();

      const model = this.gui.addFolder('Model');
      this.enableDraggingGui = model.add(this.params, 'enableDragging')
        .name('Enable Dragging')
        .onChange((value) => {
          this.handleEnableDragging(value);
        });

      model.add(this.params, 'modelRotation', 0, 360).name('Model Rotation').onChange(this.onModelChange);

      model.add({ showModelInfo: () => this.showModal = true }, "showModelInfo").name("Model Info");



    },
    handleEnableDragging(value) {
      if (value) {
        this.dragobjects.push(this.boxedModel);
        this.scene.add(this.boxedModel);
      } else {
        this.dragobjects.length = 0;
        this.scene.remove(this.boxedModel);
      }
    },
    addGuiElements(){
      this.$refs.guiContainer.appendChild(this.gui.domElement);
    },
    showUI(){
      
      this.$nextTick(() => {
        this.$refs.guiContainer.style.display = "block";
        let elements = this.$refs.guiContainer.children.length;
        if(elements == 0){
          this.addGuiElements();
        }
      });
    },
    hideUI(){      
      this.$nextTick(() => {
        this.$refs.guiContainer.style.display = "none";
      });
    },
    async loadEnvMap(url) {
      try {

        if(url == null){
          this.params.envMap = Object.keys(this.params.envMaps)[0];
          url = this.params.envMaps[this.params.envMap];
        }


        const texture = await new Promise((resolve, reject) => {
          new RGBELoader()
            .load(
              url,
              (texture) => {
                if (this.scene.environment) {
                  this.scene.environment.dispose();
                }

                texture.mapping = THREE.EquirectangularReflectionMapping;
                this.scene.environment = texture;
                this.pathTracer.updateEnvironment();
                this.onParamsChange();

                resolve(texture); // Resolves the promise when the texture is successfully loaded
              },
              undefined, // Optionally, you can handle progress updates here
              (error) => {
                reject(error); // Rejects the promise if an error occurs
              }
            );
        });

        console.log('Texture loaded:', texture);
      } catch (error) {
        console.error('Error loading texture:', error); // Foutmelding wordt hier afgevangen
      }
    },
    updateLightParams(){
      this.sunlight.color.set(this.params.lightColor);
      this.sunlight.intensity = this.params.lightIntensity;
      this.sunlight.position.y = this.params.lightHeight; 

      const angleInRadians = (this.params.angle * Math.PI) / 180;
        this.sunlight.position.set(
          Math.cos(angleInRadians) * 5,
          this.sunlight.position.y,
          Math.sin(angleInRadians) * 5
        );

        // this.scene.backgroundIntensity = this.params.environmentIntensity;
        // this.scene.backgroundRotation.y = this.params.environmentRotation;

      // this.ambientLight.color.set(this.params.ambientColor);
      // this.ambientLight.intensity = this.params.ambientIntensity;
      
      if(this.usePathTracing){
        this.pathTracer.updateLights();
      }
    }
  },
  computed: {

    showAddTiles: function(){

      if( this.currentRDX && this.currentRDY) return false; 

      return this.useControls


    }

  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
    if (this.gui) {
      this.gui.destroy();
    }

    this.threecontainer.removeEventListener('mouseenter', this.hideControlIcon);
    this.threecontainer.removeEventListener('mouseleave', this.showControlIconHandler);
}
};
</script>

<style scoped>

.three-container {
  cursor: grab;
  width: 100%;
  height: 100%;
}

.three-container:active {
  cursor: grabbing;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.centerIcon{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.centerIcon.show {
  animation: fadeIn 0.7s ease-in-out forwards;
}

.centerIcon.hide {
  animation: fadeOut 1s ease-in-out forwards;

}

.overlay{
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%); 
  background: rgba(255, 255, 255, 0.5); 
  padding: 10px 20px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
  z-index: 1000;
  display: flex;
  gap: 10px;
}

.menu{
  display: none;
  top: 10px;
  right:10px;
  position: absolute;
  background: rgba(255, 255, 255, 0.5); 
  padding: 10px;
  border-radius: 5px;
}

::v-deep(.lil-gui) {
  background-color: #6c757d;
}

::v-deep(.lil-gui .title) {
  background-color: #6c757d;
  font-size: 12px !important;
  font-family: 'AvenirLight';
}

.sampleCounter{
  position: absolute; 
  bottom: 30px; 
  left: 10px; 
  color: black; 
  background: white; 
  padding: 5px; 
  border-radius: 5px; 
  z-index: 1000;
}

@keyframes pop {
  0% { transform: scale(1); }
  50% { transform: scale(1.2); }
  100% { transform: scale(1); }
}

.fullscreen-btn {
  position: absolute;
  bottom: 30px;
  right: 30px;
  background: rgba(0, 0, 0, 0);
  color: white;
  border: none;
  cursor: pointer;
  font-size: 30px;
  transition: transform 0.15s ease-out, background-color 0.15s ease-out;
}
.fullscreen-btn:hover {
  animation: pop 0.6s ease-out;
}

.btn{
  margin-left: 2px;
}

</style>
