import * as THREE from "three";
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import proj4 from 'proj4';
import * as X2JS from 'x2js';

export default class TileFetcher {
    showTileGrid= null;
    hideCenterBuilding = null;
    scene = null;
    wgs84 = "EPSG:4326";
    rd = "EPSG:28992";
    rdx= null;
    rxy= null;
    localRdX=null;
    localRdY=null;
    buildingOffsetCenterX=null;
    buildingOffsetCenterY=null;
    transformOffsetX = null;
    transformOffsetY= null;
    threshold= null;
    baseUrl3dtiles= null;
    tileCenterX=0;
    tileCenterY=0;
    tileCenterHeight=0;
    bagMaterial=new THREE.MeshStandardMaterial({
              color: 0xaaaaaa, 
              roughness: 0.1,  
              metalness: 0.2
    });
    tileset=null;
    textureLoader = new THREE.TextureLoader();

    wmtsurl = null;
    wmtslayer = null;

    options = null;

    tileGridLines= new THREE.Group();

    tileMatrixSet = null;

    constructor(options) {

        this.options = options;

        this.baseUrl3dtiles = this.getBaseUrl(options.url3dtileset);
        this.wmtsurl = options.wmtsurl;
        this.wmtslayer = options.wmtslayer;

        this.showTileGrid = options.showTileGrid;

        proj4.defs(
            "EPSG:28992",
            "+proj=sterea +lat_0=52.15616055555555 +lon_0=5.38763888888889 " +
            "+k=0.9999079 +x_0=155000 +y_0=463000 +ellps=bessel " +
            "+towgs84=565.2369,50.0087,465.658,1.042,0.214,0.631,-8.15 +units=m +no_defs"
        );

        if(options.hideCenterBuilding != null){
            this.hideCenterBuilding = options.hideCenterBuilding;
        }

        this.scene = options.scene;
        this.threshold = options.threshold;
        this.tiles = [];
        this.loader = new GLTFLoader();

        if(options.lat != null){
            const rdCoords = proj4(this.wgs84, this.rd, [options.lon, options.lat]);
            this.rdx = rdCoords[0];
            this.rdy = rdCoords[1];
        }
        else if(options.rdx != null){
            this.rdx = options.rdx;
            this.rdy = options.rdy
        }
        else{
            throw new Error('option object does not have a coordinate!');
        }

        this.loadWmtsTiles(options.wmtsCrs);
        
        this.loadTilesetFile(options.url3dtileset);

        this.toggleTileGrid(this.showTileGrid);
    }

    getBaseUrl(tilesetUrl) {
        const url = new URL(tilesetUrl);
        return url.origin + url.pathname.substring(0, url.pathname.lastIndexOf("/") + 1);
    }

    async loadWmtsTiles(crs){
        let capabilities = await this.getCapabilities();

        const tileMatrixSets = capabilities.Capabilities.Contents.TileMatrixSet;
        this.tileMatrixSet = tileMatrixSets.find(
            (tms) => tms.Identifier.toString() === crs
        );

        this.updateWmtsTiles();

    }

    updateWmtsTiles(){
        let tilematrix = this.tileMatrixSet.TileMatrix[this.options.wmtsZoom];
        this.getWmtsTiles(tilematrix, this.options.wmtsZoom);

        let tilematrixLarge = this.tileMatrixSet.TileMatrix[this.options.wmtsZoom-4];
        this.getWmtsTiles(tilematrixLarge, this.options.wmtsZoom-4);
    }

    getWmtsTiles(tilematrix, zoom){

        const coordX = this.rdx;
        const coordY = this.rdy

        const topLeftCorner = tilematrix.TopLeftCorner.split(" ").map(Number);
        const scaleDenominator = Number(tilematrix.ScaleDenominator);
        const tileWidth = Number(tilematrix.TileWidth);
        const tileHeight = Number(tilematrix.TileHeight);

        const result = this.calculateWMTSGrid(coordX, coordY, topLeftCorner, scaleDenominator, tileWidth, tileHeight);

        let parentX  = Math.floor(result.tileX/2);
        let parentY  = Math.floor(result.tileY/2);

      //  console.log(`parentX:${parentX} parentY:${parentY}`);

        // let leftTopX = parentX*2; //lefttopX
        // let leftTopY = parentY*2; //lefttopY

        // console.log(`tileX:${result.tileX} tileY:${result.tileY}`);
        // console.log(`lefttopX:${leftTopX} lefttopY:${leftTopY}`);

        // let tilePosX = result.tileX - leftTopX;
        // let tilePosY = result.tileY - leftTopY;

       // console.log(`posX:${tilePosX} posY:${tilePosY}`);


        // //load 4 zoom 14 tiles that fit in the parent of the requested tile
        // for (let x = 0; x <= 1; x++) {
        //     for (let y = 0; y <= 1; y++) {
        //         let url = `${this.wmtsurl}&layer=${this.wmtslayer}&style=default&tileMatrixSet=EPSG:28992&service=WMTS&request=GetTile&version=1.0.0&format=image/jpg&TileCol=${leftTopX+x}&TileRow=${leftTopY+y}&tileMatrix=${zoom}`;
        //         this.addWmtsTile(url,result.tileSizeMetersX, result.tileSizeMetersY, x,y, result.coordOffX, result.coordOffY);
        //     }
        // }

        // //
        // let url = `${this.wmtsurl}&layer=${this.wmtslayer}&style=default&tileMatrixSet=EPSG:28992&service=WMTS&request=GetTile&version=1.0.0&format=image/jpg&TileCol=${parentX}&TileRow=${parentY}&tileMatrix=${zoom-1}`;
        // this.addWmtsTile(url,result.tileSizeMetersX*2, result.tileSizeMetersY*2, 0,0, result.coordOffX, result.coordOffY);
   
    
        for (let rowOffset = -this.options.wmtsGridSize; rowOffset < this.options.wmtsGridSize; rowOffset++) {
            for (let colOffset = -this.options.wmtsGridSize; colOffset < this.options.wmtsGridSize; colOffset++) {
                let url = `${this.wmtsurl}&layer=${this.wmtslayer}&style=default&tileMatrixSet=EPSG:28992&service=WMTS&request=GetTile&version=1.0.0&format=image/jpg&TileCol=${result.tileX+colOffset}&TileRow=${result.tileY+rowOffset}&tileMatrix=${zoom}`;
                this.addWmtsTile(url,result.tileSizeMetersX, result.tileSizeMetersY, colOffset,rowOffset, result.coordOffX, result.coordOffY, zoom);
            }
        }
    }

    addWmtsTile(tileUrl, tileSizeMetersX,tileSizeMetersY, nrx, nry, coordOffX, coordOffY, zoom){

        if (!this.wmtsTileGroup) {
            this.wmtsTileGroup = new THREE.Group();
            this.scene.add(this.wmtsTileGroup); 
        }

        this.textureLoader.load(tileUrl, (texture) => {
            texture.colorSpace = THREE.SRGBColorSpace; 

            const planeGeometry = new THREE.PlaneGeometry(tileSizeMetersX, tileSizeMetersY);
            const planeMaterial = new THREE.MeshStandardMaterial({ 
                map: texture,
            });
            const plane = new THREE.Mesh(planeGeometry, planeMaterial);
            plane.receiveShadow = true

            plane.rotation.x = -Math.PI / 2;

            this.wmtsTileGroup.add(plane);

            let x = (nrx * tileSizeMetersX) + (tileSizeMetersX/2) - coordOffX; 
            let y = (nry * tileSizeMetersY) + (tileSizeMetersY/2) - coordOffY; 
            plane.position.set(x, -(14-zoom)/10, y); //quick fix: place bigger tiles slightly under smaller tiles to prevent z-fighting

            const box = new THREE.Box3().setFromObject(plane);
            const helper = new THREE.Box3Helper(box, 0x00ff00);
            this.tileGridLines.add(helper);


        });
    }

    calculateWMTSGrid(coordX, coordY, topLeftCorner, scaleDenominator, tileWidth, tileHeight) {
        
        // Standaard pixelgrootte volgens de WMTS-specificatie
        // De waarde 0.00028 is afkomstig van de standaard pixelgrootte die in de OGC WMTS-specificatie wordt gebruikt 
        // om de schaal (ScaleDenominator) om te rekenen naar de resolutie (afstand per pixel). 
        // Deze waarde is gebaseerd op een standaard die een inch definieert als 0,0254 meter 
        // en gaat ervan uit dat er 90,7 pixels per inch zijn
        const pixelSizeMeters = 0.00028;
    
        // Resolutie berekenen (meters per pixel)
        const resolution = scaleDenominator * pixelSizeMeters;
    
        // Grootte van een tile in kaart-eenheden
        const tileSizeMetersX = tileWidth * resolution;
        const tileSizeMetersY = tileHeight * resolution;

        // TopLeftCorner van het grid
        const [topLeftX, topLeftY] = topLeftCorner;
    
        // Offset berekenen van TopLeftCorner naar de coördinaat
        const offsetX = coordX - topLeftX;
        const offsetY = topLeftY - coordY;
        
        const tileOffsetX = offsetX / tileSizeMetersX;
        const tileOffsetY = offsetY / tileSizeMetersY;
    
        // Aantal tegels berekenen vanaf de TopLeftCorner
        const tileX = Math.floor(tileOffsetX);
        const tileY = Math.floor(tileOffsetY);

        const coordOffX = (tileOffsetX - tileX) * tileSizeMetersX;
        const coordOffY = (tileOffsetY - tileY) * tileSizeMetersY;
    
        return { tileX, tileY, tileSizeMetersX, tileSizeMetersY, coordOffX, coordOffY };
    }

    hasSameParent(row1, col1, row2, col2) {
        return Math.floor(row1 / 2) === Math.floor(row2 / 2) &&
               Math.floor(col1 / 2) === Math.floor(col2 / 2);
    }

    updateWmtsTile(url, layer){
        this.wmtsurl = url;
        this.wmtslayer = layer;
        this.removeWmtsTiles();
        this.updateWmtsTiles();
    }

    removeWmtsTiles() {
        if (this.wmtsTileGroup) {
            this.scene.remove(this.wmtsTileGroup);
            this.wmtsTileGroup = null;
        }
    }

    loadTilesetFile(url) {
        fetch(url)
            .then(response => response.json())
            .then(tileset => {
                this.tileset = tileset;
                this.processTileset(tileset);
            })
            .catch(error => {
                console.error('Error loading tileset:', error);
            });
    }

    processTileset(tileset) {
        const root = tileset.root;
        const transform = root.transform;
  
        this.transformOffsetX = transform[12];
        this.transformOffsetY = transform[13];

        this.localRdX = this.rdx - this.transformOffsetX;
        this.localRdY = this.rdy - this.transformOffsetY;
  
        const foundTile = this.findTileRecursive(root);
  
        if (foundTile) {
          let tileurl = this.baseUrl3dtiles + foundTile.content.uri;

            //local center
            this.tileCenterX = foundTile.boundingVolume.box[0];
            this.tileCenterY = foundTile.boundingVolume.box[1];
            this.tileCenterHeight = foundTile.boundingVolume.box[2];

            this.buildingOffsetCenterX = this.rdx - this.transformOffsetX - this.tileCenterX;
            this.buildingOffsetCenterY = -(this.rdy - this.transformOffsetY - this.tileCenterY);

            this.loadTile(tileurl, this.hideCenterBuilding);

            let neighbourTiles = this.findNeighbourTilesWithContent(tileset, foundTile);
            for (let neighbour of neighbourTiles) {
                let url = this.baseUrl3dtiles + neighbour.content.uri;
                this.loadTile(url, false);
            }
  
        } else {
          // console.log("Geen passende tile gevonden.");
        }
    }

    findTileRecursive(tile) {

        if (this.isCoordinateInTile(tile)) {
            if (tile.content && tile.content.uri) {
                return tile;
            }
        }

        if (tile.children) {
            for (let child of tile.children) {
                const found = this.findTileRecursive(child);
                if (found) {
                    return found;
                }
            }
        }
        return null;
    }

    findNeighbourTilesWithContent(tileset, targetTile) {
        const neighbors = [];

        var that = this;

        function traverse(tile) {
            const box = tile.boundingVolume?.box;
  
            if (box) {
                const distanceX = Math.abs(box[0] - that.localRdX); // targetBox[0]);
                const distanceY = Math.abs(box[1] - that.localRdY); // targetBox[1]);
  
                if (distanceX <= that.threshold && distanceY <= that.threshold && tile !== targetTile) {
                    if (tile.content && tile.content.uri) {
                        neighbors.push(tile);
                    }
                }
            }
  
            if (tile.children) {
                tile.children.forEach(child => traverse(child));
            }
        }
  
        traverse(tileset.root);
        return neighbors;
    }
    
    isCoordinateInTile(tile) {

        const boundingBox = tile.boundingVolume.box;

        const cx = boundingBox[0];
        const cy = boundingBox[1];
        const hx = boundingBox[3];
        const hy = boundingBox[7];

        const minX = cx - hx;
        const maxX = cx + hx;
        const minY = cy - hy;
        const maxY = cy + hy;

        return this.localRdX >= minX && this.localRdX <= maxX && this.localRdY >= minY && this.localRdY <= maxY;
    }

    loadTile(path, hideCenterBuilding){
        fetch(path)
        .then(response => response.arrayBuffer())
        .then(buffer => this.processB3DM(buffer, hideCenterBuilding));
    }

    processB3DM(buffer, hideCenterBuilding) {

        const headerByteLength = 28;
  
        // Lees de binaire header
        const header = new DataView(buffer, 0, headerByteLength);
        const magic = String.fromCharCode(
            header.getUint8(0),
            header.getUint8(1),
            header.getUint8(2),
            header.getUint8(3)
        );
  
        if (magic !== 'b3dm') {
            throw new Error('Invalid b3dm file');
        }
  
        // Strip de header van de buffer
        const glbData = buffer.slice(headerByteLength);
  
        const version = header.getUint32(4, true); // Little-endian
        if (version !== 1) {
          throw new Error(`Unsupported b3dm version: ${version}`);
        }
  
        const byteLength = header.getUint32(8, true);
        const featureTableJSONByteLength = header.getUint32(12, true);
        const featureTableBinaryByteLength = header.getUint32(16, true);
        const batchTableJSONByteLength = header.getUint32(20, true);
        const batchTableBinaryByteLength = header.getUint32(24, true);


   // Batch Table JSON Uitlezen
   const batchTableStart = headerByteLength + featureTableJSONByteLength + featureTableBinaryByteLength;
   const batchTableJSONBuffer = buffer.slice(batchTableStart, batchTableStart + batchTableJSONByteLength);

   let batchTableJSON = {};
   if (batchTableJSONByteLength > 0) {
       const decoder = new TextDecoder("utf-8");
       const batchTableString = decoder.decode(batchTableJSONBuffer);
       batchTableJSON = JSON.parse(batchTableString);
   }

        // Start of glTF data
        const glTFStart =
          headerByteLength +
          featureTableJSONByteLength +
          featureTableBinaryByteLength +
          batchTableJSONByteLength +
          batchTableBinaryByteLength;
  
        // Extract the glTF binary
        let gltfBuffer = buffer.slice(glTFStart, byteLength);

        var currentMesh = null;
  
        // Parse glTF buffer
        const loader = new GLTFLoader();
        loader.parse(gltfBuffer, "", (gltf) => {
  
            let glb = gltf.scene;
  
            glb.traverse((child) => {
              if (child.isMesh) {
                currentMesh = child;
                  child.castShadow = true;
                  child.material = this.bagMaterial;
              }});
  
            glb.position.set(-this.tileCenterX-this.buildingOffsetCenterX, 0, this.tileCenterY - this.buildingOffsetCenterY);//RD Y goes up, THREE Y goes down
            //glb.position.set(-this.tileCenterX, 0, this.tileCenterY);//RD Y goes up, THREE Y goes down
            this.scene.add(glb);
  
            //prepare gridlines
            const box = new THREE.Box3().setFromObject(glb);
            const helper = new THREE.Box3Helper(box, 0xff0000);
            this.tileGridLines.add(helper);
 
            if(hideCenterBuilding){
                const raycaster = new THREE.Raycaster();
                const rayOrigin = new THREE.Vector3(0, 100, 0); // Y = 100 (boven de tile)
                const rayDirection = new THREE.Vector3(0, -1, 0); // Richting naar beneden

                raycaster.set(rayOrigin, rayDirection.normalize());

                const intersects = raycaster.intersectObject(glb, true);

                if (intersects.length > 0) {
                    const { object, face } = intersects[0];

                    const batchidAttr = object.geometry.getAttribute('_batchid');

                    if (batchidAttr) {
                        let batchid = batchidAttr.getX(face.a);
                        let baginfo = batchTableJSON.attributes[batchid];
                        
                        //hide building that we hit by centering all vertices
                        this.moveVerticesToOriginByBatchId(currentMesh, batchid);
                    
                    } else {
                        console.log("Geen batch ID gevonden op de geraakt object.");
                    }
                } else {
                    console.log("Geen intersecties gevonden.");
                }
            }

          },
          (error) => {
            console.error("Failed to parse glTF from b3dm:", error);
          }
        );
    }

    moveVerticesToOriginByBatchId(mesh, targetBatchId) {
        const geometry = mesh.geometry;
      
        const batchIdAttr = geometry.getAttribute('_batchid');
        const positionAttr = geometry.attributes.position;
      
        if (!batchIdAttr || !positionAttr) {
          console.error('Geometry mist benodigde attributen: _batchid of position');
          return;
        }

        for (let i = 0; i < batchIdAttr.count; i++) {
          const batchId = batchIdAttr.getX(i);
          if (batchId === targetBatchId) {
            positionAttr.setXYZ(i, 0, 0, 0);
          }
        }
      
        positionAttr.needsUpdate = true;
        geometry.computeBoundingBox();
        geometry.computeBoundingSphere();
    }

    async getCapabilities() {
        let url = "https://service.pdok.nl/brt/achtergrondkaart/wmts/v2_0?request=GetCapabilities&service=WMTS";
        var x2js = new X2JS();
    
        try {
            const res = await fetch(url);
            const xml = await res.text();
            const json = x2js.xml2js(xml);
            return json;
        } catch (error) {
            console.error("Fout bij het ophalen van de capabilities:", error);
            throw error;
        }
    }

    toggleTileGrid(isvisible){
        if(isvisible){
        this.scene.add(this.tileGridLines);
        }
        else{
            this.scene.remove(this.tileGridLines);
        }
    }



}