<template>
  <div class="threecontainer">
    <ThreeViewer  :showTileGrid="false" :tileRadius="2000" :useControls="true"  :shortCode="shortCode"></ThreeViewer>    
  </div>

</template>


<script>

import ThreeViewer from '../components/ThreeViewer.vue';

export default {
  name: 'ModelViewer',
  props: ['shortCode'],
  components: {
    ThreeViewer
  },
  mounted: async function(){
    this.$emit('setHeaderFooter', false);
  }
}
</script>

<style scoped>

.threecontainer{
  position: fixed;
  width: 100%;
  height: 100%;
}

</style>