<template>
  <div>
      <div>{{ this.$utils.uploadStatus }}</div>
      <button @click="upload">Upload Model</button>
      <button @click="addtree">Add tree</button>
      <button @click="addbush">Add bush</button>

      <modelrow v-if="this.$utils.state.userModelUrl != ''" 
          :model-src="this.$utils.state.userModelUrl"
          :background-color="'#F5F5F5'"
          :qr-code-size="150"
          :short-code="this.$utils.shortCode">
      </modelrow>

      <div ref="container"></div>
  </div>
</template>

<script>
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import DragControls from 'three-dragcontrols';
import ModelRow from '../components/ModelRow.vue';
import { OrbitControls } from 'three/addons/controls/OrbitControls.js';

export default {
  data: function () {
      return {
          dragobjects: [],
          dragobjectCenters: [], // Parallel array to store y centers
          scene: new THREE.Scene(),
          group: new THREE.Group(),
          dragControls: null,
          orbitControls: null
      };
  },
  mounted() {
      this.$utils.siteLog("Design view");

      const light = new THREE.DirectionalLight(0xffffff, 2);
      light.position.set(5, 10, 7.5);
      light.castShadow = true;
      this.scene.add(light);

      const ambientLight = new THREE.AmbientLight(0x404040);
      this.scene.add(ambientLight);

      this.renderer = new THREE.WebGLRenderer();
      this.renderer.setSize(window.innerWidth, window.innerHeight);
      this.renderer.shadowMap.enabled = true; // Enable shadow maps
      this.$refs.container.appendChild(this.renderer.domElement);

      this.scene.background = new THREE.Color(0xffffff);

      this.camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
      this.camera.position.y = 2;
      this.camera.position.z = 5;

      this.orbitControls = new OrbitControls(this.camera, this.renderer.domElement);

      // Add ground plane
      this.addGroundPlane();

     // this.addCube(0xff0000, 0.02);

      this.dragControls = new DragControls(this.dragobjects, this.camera, this.renderer.domElement);
      var that = this;

      this.dragControls.addEventListener('dragstart', function() {
          that.orbitControls.enabled = false;
      });

      this.dragControls.addEventListener('dragend', function() {
          that.orbitControls.enabled = true;
      });

      this.dragControls.addEventListener('drag', function(event) {
          const index = that.dragobjects.indexOf(event.object);
          if (index !== -1) {
              event.object.position.y = that.dragobjectCenters[index];
          }
      });

      this.addtree();
      this.addbush();

      this.animate();
  },
  methods: {
      animate() {
          requestAnimationFrame(this.animate);
          this.renderer.render(this.scene, this.camera);
      },
      addGroundPlane() {
          const geometry = new THREE.PlaneGeometry(5, 5);
          const material = new THREE.MeshStandardMaterial({ color: 0x00ff00 });
          const plane = new THREE.Mesh(geometry, material);
          plane.name = "floor";
          plane.rotation.x = -Math.PI / 2;
          plane.position.y = 0; // Set ground plane position at y = 0
          plane.receiveShadow = true;
          this.scene.add(plane);
      },
      addtree() {
          this.loadGLBModel('assets/tree.glb', 1);
      },
      addbush() {
           this.loadGLBModel('assets/high_hedge.glb', 0.01);
          //  this.loadGLBModel('assets/wildeman-maquette.glb', 0.01);

            

      },
      upload() {
          var scene = new THREE.Scene();
          var group = new THREE.Group();

          this.scene.children.forEach((child) => {
              if (!child.isLight && !child.isCamera && child.name != "floor") {

                if (child.name == "invisiblebox") 
                {
                  let parentPosition = child.position.clone();
                  child = child.children[0];

                  const clonedChild = child.clone();
                  clonedChild.position.add(parentPosition);
                  group.add(clonedChild);

                }
                else{
                  const clonedChild = child.clone();
                  group.add(clonedChild);
                }
                 
              }
          });

          group.scale.set(0.1, 0.1, 0.1);

          scene.add(group);
          this.$utils.uploadUserModel(scene);
      },
      addCube(cubecolor, direction) {
          const geometry = new THREE.BoxGeometry();
          const material = new THREE.MeshStandardMaterial({ color: cubecolor, roughness: 0, metalness: 0.5 });
          var cube = new THREE.Mesh(geometry, material);
          cube.name = "kubus";

          cube.castShadow = true;
          cube.position.y = 0.5; // Adjust to ensure the cube is on the ground
          this.dragobjects.push(cube);
          this.dragobjectCenters.push(cube.position.y); // Add the center y position
          this.scene.add(cube);
      },
      loadGLBModel(path, scale) {
          const loader = new GLTFLoader();
          loader.load(path, (gltf) => {
            gltf.scene.scale.set(scale, scale, scale);

              gltf.scene.name = "glbmodel";
              const box = new THREE.Box3().setFromObject(gltf.scene);
              const boxSize = box.getSize(new THREE.Vector3());
              const boxCenter = box.getCenter(new THREE.Vector3());

              const geometry = new THREE.BoxGeometry(boxSize.x, boxSize.y, boxSize.z);
              const material = new THREE.MeshBasicMaterial({ color: 0x000000, transparent: true, opacity: 0 });
              const invisibleBox = new THREE.Mesh(geometry, material);
              invisibleBox.name = "invisiblebox";
              invisibleBox.position.copy(boxCenter);

              gltf.scene.traverse((node) => {
                  if (node.isMesh) {
                      node.castShadow = true;
                  }
              });

              invisibleBox.add(gltf.scene);
              gltf.scene.position.set(-boxCenter.x, -boxCenter.y, -boxCenter.z);

              // Adjust the position to ensure the model is on the ground
              invisibleBox.position.y = boxSize.y / 2;

              this.scene.add(invisibleBox);
              this.dragobjects.push(invisibleBox);
              this.dragobjectCenters.push(invisibleBox.position.y); // Add the center y position
          },
          undefined,
          (error) => {
              console.error('Er is een fout opgetreden bij het laden van het GLB-model:', error);
          });
      }
  },
  components: {
      'modelrow': ModelRow
  },
  beforeDestroy() {
  }
};
</script>

<style scoped>
</style>
