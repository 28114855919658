<template>
    <div id="app">

        <h1>{{ currentVideoName }}</h1>

        <p>
            <video :key="currentVideo" height="600" controls>
            <source :src="currentVideo" type="video/mp4">
            Your browser does not support the video tag.
            </video>
        </p>

        <b-dropdown id="dropdown-1" :text="currentVideoName" class="m-2">
      <b-dropdown-item 
        v-for="video in videos" 
        :key="video.url" 
        @click="setVideo(video.url, video.name)">
        {{ video.name }}
      </b-dropdown-item>
    </b-dropdown>
    

    </div>
  </template>
  
  <script>

  
  export default {
    name: 'Videos',
    data: function () {
      return{
        videos:[
        {
                url: "https://dupdatatavjw5bpo7yfmc.blob.core.windows.net/videos/IMG_0167.mp4",
                name: "Wildemanbuurt 360"
                },
                {
                url: "https://dupdatatavjw5bpo7yfmc.blob.core.windows.net/videos/IMG_0160.mp4",
                name: "Wildemanbuurt Maquette"
                },
                {
                url: "https://dupdatatavjw5bpo7yfmc.blob.core.windows.net/videos/2D3824D5-DF4B-4158-9C27-8F25EE761F6C-1033-0000027C801F5506.mp4",
                name: "Zandkasteel"
                },
                {
                url: "https://dupdatatavjw5bpo7yfmc.blob.core.windows.net/videos/C3C9CCB3-874F-4318-9676-67A197397955-1033-0000027B39A545CE.mp4",
                name: "En Amsterdam"
                },
                {
                url: "https://dupdatatavjw5bpo7yfmc.blob.core.windows.net/videos/C5B0CFDE-CC03-4ED3-9D3C-5673168D3AC6-1033-0000027B8F520574.mp4",
                name: "Paasheuvelweg 17"
                },
                {
                url: "https://dupdatatavjw5bpo7yfmc.blob.core.windows.net/videos/CEC9C877-4690-4EF4-AB12-A2F9BD1DAFD7-1033-0000027BF391DDC8.mp4",
                name: "Ruby Gardens"
                },
                {
                url: "https://dupdatatavjw5bpo7yfmc.blob.core.windows.net/videos/ARTool.mp4",
                name: "AR Tool demoreel"
                }
        ],
        currentVideo: "",
        currentVideoName: ""
      }
    },
    mounted() {
        this.currentVideo = this.videos[0].url;
        this.currentVideoName = this.videos[0].name;
    },
    methods: {
        setVideo(url, name){
            this.currentVideo = url;
            this.currentVideoName = name;
        }
    }
  }
  </script>
  
  <style>
  </style>